import { Expression } from 'catalan-dictionary-common';
import TranslationDisplay from './TranslationDisplay';

interface Props {
  expressions: Expression[],
  isCatalan: boolean,
}

function Expressions(props: Props) {
  const expressions = props.expressions || [];

  if (expressions.length === 0) {
    return <></>;
  }

  const expressionsHtml = expressions.map((expression, expressionIndex) => {
    const translationsHtml = expression.translations.map((translation, translationIndex) => {
      return (
        <li key={`expression-${expressionIndex}-translation-${translationIndex}`}>
          <TranslationDisplay translation={translation} isCatalan={props.isCatalan} />
        </li>
      );
    });
    return (
      <li className="expression" key={`expression-${expressionIndex}`}>
        <span className="expression-text">{expression._text}</span>
        <ol>
          {translationsHtml}
        </ol>
      </li>
    );
  });

  return (
    <div className="expressions-section">
      <h3>Expressions</h3>
      <ul className="expressions-list">
        {expressionsHtml}
      </ul>
    </div>
  );
}

export default Expressions;