"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formatTense = exports.TENSE_TO_LONG_FORM = exports.VerbTense = exports.VerbMode = void 0;
var VerbMode;
(function (VerbMode) {
    VerbMode["Indicative"] = "I";
    VerbMode["Subjunctive"] = "S";
    VerbMode["Imperative"] = "M";
    VerbMode["ImpersonalForms"] = "O";
})(VerbMode = exports.VerbMode || (exports.VerbMode = {}));
var VerbTense;
(function (VerbTense) {
    VerbTense["Present"] = "Pr";
    VerbTense["Perfect"] = "Pe";
    VerbTense["Imperfect"] = "Im";
    VerbTense["Plusquamperfect"] = "Pl";
    VerbTense["PastSimple"] = "Ps";
    VerbTense["PastPerifrastic"] = "Pp";
    VerbTense["PastAnterior"] = "Pa";
    VerbTense["PastAnteriorPerifrastic"] = "Pf";
    VerbTense["Future"] = "Fu";
    VerbTense["FuturePerfect"] = "Fp";
    VerbTense["Conditional"] = "Co";
    VerbTense["ConditionalPerfect"] = "Cp";
    VerbTense["Infinitive"] = "In";
    VerbTense["InfinitiveComposite"] = "Ic";
    VerbTense["Gerund"] = "Ge";
    VerbTense["GerundComposite"] = "Gc";
    VerbTense["Participle"] = "Pt";
})(VerbTense = exports.VerbTense || (exports.VerbTense = {}));
exports.TENSE_TO_LONG_FORM = {
    'Pr': 'Present',
    'Pe': 'Past Perfect',
    'Im': 'Imperfect',
    'Pl': 'Pluperfect',
    'Ps': 'Past Simple',
    'Pp': 'Past Perifrastic',
    'Pa': 'Past Anterior',
    'Pf': 'Past Anterior Perifrastic',
    'Fu': 'Future',
    'Fp': 'Future perfet',
    'Co': 'Conditional',
    'Cp': 'Conditional Perfect',
    'In': 'Infinitive',
    'Ic': 'Infinitive Composite',
    'Ge': 'Gerund',
    'Gc': 'Gerund Composite',
    'Pt': 'Participle',
};
function formatTense(tense) {
    return exports.TENSE_TO_LONG_FORM[tense];
}
exports.formatTense = formatTense;
