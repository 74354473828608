import { useState, useEffect } from 'react';
import Highlighter from 'react-highlight-words';

import { Example, Word } from 'catalan-dictionary-common';
import LoadingScreen from '../LoadingScreen';
import NoResults from '../NoResults';
import { APP_ROOT_URI } from '../../config';


interface Props {
  wordDef: Word,
}
export default function WordExamples(props: Props) {
  const [isLoading, setIsLoading] = useState(true);
  const [examples, setExamples] = useState<Example[]>([]);

  useEffect(() => {
    setIsLoading(true);
    (async () => {
      await fetch(`${APP_ROOT_URI}/examples?text=${encodeURIComponent(props.wordDef.word)}&eng_text=1&lang=${props.wordDef.isCatalan ? 'cat' : 'eng'}`)
        .then((resp) => {
          return resp.json();
        })
        .then((examples: Example[]) => {
          setExamples(examples);
          setIsLoading(false);
        });
    })();
  }, [props.wordDef]);

  if (isLoading) {
    return <LoadingScreen />;
  }
  if (!examples.length) {
    return <NoResults text='No examples found.' />;
  }

  const examplesContent = examples.map((example, index) => {
    return (
      <tr key={index}>
        <td>
          <Highlighter
            highlightClassName="highlight"
            searchWords={[props.wordDef.word]}
            autoEscape={true}
            textToHighlight={example.cat_text}
          />
        </td>
        <td>
          <Highlighter
            highlightClassName="highlight"
            searchWords={[props.wordDef.word]}
            autoEscape={true}
            textToHighlight={example.eng_text}
          />
        </td>
      </tr>
    );
  });

  return (
    <div className="examples">
      <div className="citation">
        Examples are extracted automatically from Wikipedia by the&nbsp;
        <a href='https://github.com/facebookresearch/LASER/tree/main/tasks/WikiMatrix'>WikiMatrix Project</a>
        , and have not been reviewed. They are shared under&nbsp;
        <a href='https://creativecommons.org/licenses/by-sa/4.0/'>Creative Commons License (CC BY-SA 4.0)</a>.
      </div>

      <table className="table">
        <tbody>
          {examplesContent}
        </tbody>
      </table>
    </div>
  );

}
