"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WordListEntryStatus = void 0;
var WordListEntryStatus;
(function (WordListEntryStatus) {
    WordListEntryStatus["Unreviewed"] = "unreviewed";
    WordListEntryStatus["Reviewing"] = "reviewing";
    WordListEntryStatus["Learned"] = "learned";
})(WordListEntryStatus = exports.WordListEntryStatus || (exports.WordListEntryStatus = {}));
;
