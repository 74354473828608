import { useState, useEffect } from 'react';

import {
  Conjugation,
  Conjugations,
  ConjugationTense,
  formatTense,
  VerbTense,
  Word,
} from 'catalan-dictionary-common';
import LoadingScreen from '../LoadingScreen';
import NoResults from '../NoResults';
import { APP_ROOT_URI } from '../../config';


interface Props {
  wordDef: Word,
}
export default function WordConjugations(props: Props) {
  const [isLoading, setIsLoading] = useState(true);
  const [conjugations, setConjugations] = useState<Conjugations | undefined>(undefined);

  useEffect(() => {
    setIsLoading(true);
    (async () => {
      await fetch(`${APP_ROOT_URI}/words/${props.wordDef.id}/conjugations`)
        .then((resp) => {
          return resp.json();
        })
        .then((conjugation: any) => {
          conjugation.conjugations = JSON.parse(conjugation.conjugations);
          return conjugation;
        })
        .then((conjugation: Conjugation) => {
          setConjugations(conjugation.conjugations);
          setIsLoading(false);
        });
    })();
  }, [props.wordDef]);

  if (isLoading) {
    return <LoadingScreen />;
  }
  if (!conjugations) {
    return <NoResults text='No conjugations found.' />;
  }

  const createContentForTense = function(tenseShortForm: string, tenseConjugations: ConjugationTense) {
    return (
      <div key={tenseShortForm}>
        <div className="tense-title">{formatTense(tenseShortForm)}</div>
        <table className="table">
          <tbody>
            {createRow('Jo', tenseConjugations.s1)}
            {createRow('Tu', tenseConjugations.s2)}
            {createRow('Ell/Ella/Vostè', tenseConjugations.s3)}
            {createRow('Nosaltres', tenseConjugations.p1)}
            {createRow('Vosaltres', tenseConjugations.p2)}
            {createRow('Ells/Elles/Vostès', tenseConjugations.p3)}
          </tbody>
        </table>
      </div>
    );
  }

  const createRow = function(subject: string, conjugationStr: string) {
    return (
      <tr key={subject} className="conjugation-row">
        <td className="subject">{subject}</td>
        <td>{conjugationStr}</td>
      </tr>
    );
  }

  const createContentForMode = (modeShortForm: 'I' | 'S' | 'M' | 'O', mode: string, modeInEnglish: string) => {
    const rows: JSX.Element[] = [];

    const modeConjugations = conjugations[modeShortForm] || {};
    ['Pr', 'Pe', 'Im', 'Pl', 'Ps', 'Pp', 'Pa', 'Pf', 'Fu', 'Fp', 'Co', 'Cp', 'In', 'Ic', 'Ge', 'Gc', 'Pt'].map((input) => {
      const tenseShortForm = input as VerbTense
      if (modeConjugations[tenseShortForm]) {
        rows.push(createContentForTense(tenseShortForm, modeConjugations[tenseShortForm]));
      }
    });

    return (
      <div>
        <div className="mode-title">{modeInEnglish} ({mode})</div>
        {rows}
      </div>
    );
  };

  const createImpersonalForms = () => {
    const gerund = conjugations.O.Ge?.s1 || '';
    const gerundComposite = conjugations.O.Gc?.s1 || '';

    const participleConjs = conjugations.O.Pt || {};
    const participles = [participleConjs.s1, participleConjs.s2, participleConjs.s3, participleConjs.p1, participleConjs.p2, participleConjs.p3]
      .filter((entry) => entry && entry.length > 0)
      .join(', ');

    return (
      <div>
        <div className="tense-title">Gerund (Gerundi)</div>
        <div>
          <div className="conjugation-row">{gerund}, composite: {gerundComposite}</div>
        </div>
        <div className="tense-title">Participles (Participi)</div>
        <div>
          <div className="conjugation-row">{participles}</div>
        </div>
      </div>
    );
  };

  return (
    <div className="conjugations">
      <div className="citation">
        Conjugations courtesy of Wiktionary (Viccionari)&nbsp;
        <a href={`https://ca.wiktionary.org/wiki/${props.wordDef.word.toLocaleLowerCase()}`}>(see source)</a>
        , shared under&nbsp;
        <a href='https://creativecommons.org/licenses/by-sa/3.0/'>Creative Commons License (CC BY-SA 3.0)</a>.
      </div>
      {createImpersonalForms()}
      {createContentForMode('I', 'Indicatiu', 'Indicative')}
      {createContentForMode('S', 'Subjuntiu', 'Subjunctive')}
      {createContentForMode('M', 'Imperatiu', 'Imperative')}
    </div>
  );

}