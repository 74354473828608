import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faVolumeHigh } from '@fortawesome/free-solid-svg-icons'
import './play-sound-icon.scss';

interface PlaySoundProps {
  soundUrl: string,
}
export function PlaySoundIcon(props: PlaySoundProps) {
  if (!props.soundUrl) {
    return <></>;
  }
  const onPress = async () => {
    const audio = new Audio(props.soundUrl);
    audio.play();
  };
  return (
    <button onClick={onPress} className="btn btn-link play-icon">
      <FontAwesomeIcon icon={faVolumeHigh} />
    </button>
  );
}