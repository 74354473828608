"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.canonicalizeWord = void 0;
/**
 * Replace accented characters in Spanish
 * with ascii equivalent characters, lower-case,
 * replace any punctuation.
 */
function canonicalizeWord(word) {
    if (word == null) {
        return '';
    }
    return word.normalize().toLowerCase()
        // forward-facing accents
        .replace(/á/g, 'a')
        .replace(/é/g, 'e')
        .replace(/í/g, 'i')
        .replace(/ó/g, 'o')
        .replace(/ú/g, 'u')
        // backwards-facing accents
        .replace(/à/g, 'a')
        .replace(/è/g, 'e')
        .replace(/ì/g, 'i')
        .replace(/ò/g, 'o')
        .replace(/ù/g, 'u')
        // umlaut-style
        .replace(/ä/g, 'a')
        .replace(/ë/g, 'e')
        .replace(/ï/g, 'i')
        .replace(/ö/g, 'o')
        .replace(/ü/g, 'u')
        // probably not relevant, but just in case
        .replace(/ñ/g, 'ny')
        // catalan-specific chars
        .replace(/[çç]/g, 'c')
        .replace(/ŀl/g, 'l-l')
        .replace(/[·.]/g, '-')
        // spaces
        .replace(/\W+/g, '-')
        // punctuation
        .replace(/[_~'"/\\&*()!@#$%`^{}\[\];:?<>]/g, '');
}
exports.canonicalizeWord = canonicalizeWord;
;
