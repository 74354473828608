"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formatSubject = exports.formatMode = void 0;
const MODE_TO_LONG_FORM = { 'I': 'Indicative', 'S': 'Subjuntive', 'M': 'Imperative', 'O': 'Impersonal Form' };
function formatMode(mode) {
    return MODE_TO_LONG_FORM[mode];
}
exports.formatMode = formatMode;
const SUBJECT_TO_LONG_FORM = {
    s1: 'Jo',
    s2: 'Tu',
    s3: 'Ell/Ella/Vostè',
    p1: 'Nosaltres',
    p2: 'Vosaltres',
    p3: 'Ells/Elles/Vostès',
};
function formatSubject(subject) {
    return SUBJECT_TO_LONG_FORM[subject];
}
exports.formatSubject = formatSubject;
