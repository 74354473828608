import './audio-page.scss';

import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle, faPlay, faUpload } from '@fortawesome/free-solid-svg-icons'
import { Word } from 'catalan-dictionary-common';
import { APP_ROOT_URI } from '../../config';
import * as AudioRecorder from '../../services/AudioRecorder';


function AudioPage() {
  const [isLoading, setIsLoading] = useState(true);
  const [isUploading, setIsUploading] = useState(false);
  const [word, setWord] = useState<Word>();
  const [isRecording, setIsRecording] = useState(false);
  const [audioFile, setAudioFile] = useState<File | undefined>(undefined);
  const [audioDuration, setAudioDuration] = useState(0);
  const [elapsedTimeStr, setElapsedTimeStr] = useState('00:00.00');
  const [intervalId, setIntervalId] = useState<NodeJS.Timer | undefined>(undefined);

  const { search } = useLocation();
  const lang = new URLSearchParams(search).get('lang');
  const langQuery = ['cat', 'eng'].includes(`${lang}`) ? `?lang=${lang}` : '';

  let startTime = new Date(0);

  // load word definition data from URL
  const loadNextWordToRecord = async () => {
    setIsLoading(true);
    setIsRecording(false);
    setIsUploading(false);
    setElapsedTimeStr('00:00.00');
    setAudioDuration(0);
    setAudioFile(undefined);
    if (intervalId) {
      clearInterval(intervalId);
    }

    fetch(`${APP_ROOT_URI}/audio/next-to-record${langQuery}`)
      .then((resp) => {
        return resp.json();
      })
      .then((word: Word) => {
        word.definitions = JSON.parse(word.definitions);
        setWord(word);
        setIsLoading(false);
      });
    return;
  };

  function zeroPad(num: Number): string {
    if (num < 10) {
      return `0${num}`;
    }
    return `${num}`;
  }

  const toggleRecord = async () => {
    if (isRecording) {
      if (intervalId) {
        clearInterval(intervalId!);
      }
      const newFile = await AudioRecorder.stopRecordingAndCreateFile(word!.word, word!.isCatalan, word!.id);
      setAudioFile(newFile);
      setAudioDuration(new Date().getTime() - startTime.getTime());
    } else {
      AudioRecorder.record();
      startTime = new Date();
      setIntervalId(setInterval(() => {
        const elapsed = new Date(new Date().getTime() - startTime.getTime());
        const minutes = zeroPad(elapsed.getMinutes());
        const seconds = zeroPad(elapsed.getSeconds());
        const milliseconds = zeroPad(elapsed.getMilliseconds()).substring(0, 2);
        setElapsedTimeStr(`${minutes}:${seconds}.${milliseconds}`);
      }, 100));
    }
    setIsRecording(!isRecording);
  };

  const previewRecording = () => {
    if (audioFile) {
      AudioRecorder.previewRecording(audioFile);
    }
  };

  const uploadRecording = async () => {
    if (audioFile) {
      setIsUploading(true);
      // TODO: error handling
      const audio = await AudioRecorder.uploadRecording(audioFile, audioDuration, word!.id);
      if (audio) {
        setIsUploading(false);
        loadNextWordToRecord();
      }
    }
  };

  useEffect(() => {
    loadNextWordToRecord();
  }, []);

  if (!word) {
    return (
      <div>No word found to record</div>
    )
  }

  const content = isLoading ?
    <div className="text-muted">Loading...</div> :
    <div>{word.word}</div>;

  return (
    <div>
      <div className="row">
        <div className="col">
          <div className="word">
            {content}
          </div>
          <div className="elapsed-time text-muted">
            {elapsedTimeStr}
          </div>
          <div className="buttons">
            <button className={`btn btn-outline-primary ${isLoading ? 'disabled' : ''}`} onClick={toggleRecord}>
              <FontAwesomeIcon icon={faCircle} />&nbsp;
              {isRecording ? 'Stop' : 'Record'}
            </button>
            <button className={`btn btn-outline-secondary ${(!audioFile || isRecording) ? 'disabled' : ''}`} onClick={previewRecording}>
              <FontAwesomeIcon icon={faPlay} /> Preview
            </button>
            <button className={`btn btn-outline-primary ${(!audioFile || isRecording || isUploading) ? 'disabled' : ''}`} onClick={uploadRecording}>
              <FontAwesomeIcon icon={faUpload} /> {isUploading ? 'Saving...' : 'Save'}
            </button>
          </div>
        </div>
      </div>
    </div>

  );
};

export default AudioPage;

