import { Translation } from 'catalan-dictionary-common';
import TranslationDisplay from './TranslationDisplay';

interface Props {
  translations: Translation[],
  isCatalan: boolean, // the defintion is for a catalan word, thus these translations are for eng
}

/**
 * Translations by type of word (e.g. nouns, adverbs, etc.)
 */
function TranslationsGroup(props: Props) {
  if (!props.translations || !props.translations.length) {
    return <></>;
  }

  const listHtml = props.translations.map((translation: Translation, index) => {
    return (
      <li key={index}>
        <TranslationDisplay translation={translation} isCatalan={props.isCatalan} />
      </li>
    );
  });

  return (
    <ol>
      {listHtml}
    </ol>
  );
}

export default TranslationsGroup;