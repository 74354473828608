import './styles/style.scss';

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Outlet,
} from 'react-router-dom';
import { useEffect } from 'react';

import SearchBar from './components/SearchBar';
import DefinitionPage from './components/definition/DefinitionPage';
import AudioPage from './components/audio/AudioPage';


function App() {

  useEffect(() => {
    document.title = 'Catalan Dictionary';
  }, []);

  return (
    <div className="App">
      <Router>
        <nav className="navbar sticky-top navbar-primary">
          <div className="container">
            <SearchBar />
          </div>
        </nav>

        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="/translation/:word/:catOrEng" element={<DefinitionPage />} />
            <Route path="/recording" element={<AudioPage />} />
          </Route>
        </Routes>
      </Router>
    </div>
  );
}

function Layout() {
  return (
    <div className="main-layout container">
      <Outlet />
    </div>
  )
}

function Home() {
  return (
    <div style={{ padding: 20 }}>
      <h2>Home</h2>
      <p>Welcome to Catalan-English Dictionary</p>
    </div>
  );
}

export default App;
