import { useState } from 'react';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { useNavigate } from 'react-router';

import 'react-bootstrap-typeahead/css/Typeahead.css';
import { Word } from 'catalan-dictionary-common';
import { APP_ROOT_URI } from '../config';


const SEARCH_URI = `${APP_ROOT_URI}/words`;

function SearchBar() {
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([] as Word[]);

  const navigate = useNavigate();

  const handleSearch = (query: string) => {
    setIsLoading(true);

    fetch(`${SEARCH_URI}?q=${query}`)
      .then((resp) => {
        return resp.json();
      })
      .then((results) => {
        const data = results.map((word: Word) => ({
          id: word.id,
          word: word.word,
          // add briths flag after English words to disambiguate
          wordDisplay: `${word.word}${word.isCatalan ? '' : ' 🇬🇧'}`,
          word_canonical: word.word_canonical,
          isCatalan: word.isCatalan,
        }));

        setOptions(data);
        setIsLoading(false);
      });
  };

  return (
    <div className="search-bar">
      <AsyncTypeahead
        filterBy={() => true}
        id="search-bar"
        isLoading={isLoading}
        clearButton={true}
        highlightOnlyResult={true}
        labelKey="wordDisplay"
        onChange={(selected) => {
          // should never happen...
          if (selected.length !== 1) {
            return;
          }
          // TODO: not sure the correct way to avoid this typecasting
          const wordDef: Word = selected[0] as Word;
          navigate(`/translation/${wordDef.word_canonical}/${wordDef.isCatalan ? 'cat' : 'eng'}`);
        }}
        minLength={1}
        onSearch={(query: string) => handleSearch(query)}
        options={options}
        placeholder="Search for a word in Catalan or English..."

      />
    </div>
  );
};

export default SearchBar;