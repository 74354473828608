import { Translation } from 'catalan-dictionary-common';
import WordLink from './WordLink';

interface Props {
  translation: Translation,
  isCatalan: boolean, // the defintion is for a catalan word, thus these translations are for eng
}

// TODO: this is getting large and complex, should probably a lot of subcomponents

/**
 * All the information for one translation
 */
function TranslationDisplay(props: Props) {
  const translation = props.translation;
  if (!translation) {
    return <></>;
  }

  let engNote = <></>;
  if (translation.engnote && props.isCatalan) {
    engNote = <div className="alert alert-light">Note: {translation.engnote}</div>;
  }

  let catNote = <></>;
  if (translation.catnote && !props.isCatalan) {
    catNote = <div className="alert alert-light">Nota: {translation.catnote}</div>;
  }

  let acronym = '';
  if (translation.engacro && props.isCatalan) {
    acronym = ` (${translation.engacro.toUpperCase()})`;
  }
  if (translation.catacro && !props.isCatalan) {
    acronym = ` (${translation.catacro.toUpperCase()})`;
  }

  let gender = <></>;
  if (!props.isCatalan && translation.gender) {
    const genderString = (gender: string) => {
      switch (gender) {
        case 'm / f':
        case 'mf':
          return 'masculine / feminine';
        case 'm':
        case 'mpl':
          return 'masculine';
        case 'f':
        case 'fpl':
          return 'feminine';
        default:
          return '';
      }
    };
    if (genderString) {
      gender = <span className="gender-marker badge badge-pill badge-light">{genderString(translation.gender)}</span>;
    }
  }

  let categoryHtml = <></>;
  if (translation.categories && translation.categories.length > 0) {
    categoryHtml = <span className="text-muted category-label"> ({translation.categories})</span>;
  }

  const reflexive = translation.reflexive ?
    <div><em>Using reflexive form "{translation.reflexive}"</em></div> :
    <></>;

  // variations of the word, e.g. plural, feminine adjective
  let variationsHtml = <></>;
  if (props.isCatalan) {
    const variations: string[] = [];
    if (translation.plural) {
      variations.push(`plural: ${translation.plural}`);
    }
    if (translation.fems) {
      variations.push(`feminine: ${translation.fems}`);
    }
    if (translation.femplural) {
      variations.push(`feminine plural: ${translation.femplural}`);
    }
    if (variations.length > 0) {
      variationsHtml = (
        <ul className="variations">
          {variations.map((variation: string) => {
            return (
              <li key={`variation-${variation}`}>
                <em>{variation}</em>
              </li>
            );
          })}
        </ul>
      );
    }
  }

  const examples = (translation.examples || []).map((example, index) => {
    const [first, second] = props.isCatalan ? [example.eng, example.cat] : [example.cat, example.eng] ;
    return (
      <div className='example' key={index}>
        {first} <span className="text-muted"> &mdash; {second}</span>
      </div>
    );
  });

  return (
    <div>
      <div>
        <span className="translation-word">
          <WordLink word={translation._text} wordCanonical={translation._wordCanonical} isCatalan={!props.isCatalan} matchedText={translation._matchedText} />
        </span>
        {acronym}
        {gender} {categoryHtml}
        {reflexive}
        {variationsHtml}
      </div>
      {examples}
      {engNote}
      {catNote}
    </div>
  );
}

export default TranslationDisplay;