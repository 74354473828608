import MicRecorder from 'mic-recorder-to-mp3';
import { APP_ROOT_URI } from '../config';

const recorder = new MicRecorder({
  bitRate: 128
});

export function record() {
  recorder.start().then(() => {
    // something else
  }).catch((error: Error) => {
    console.error(error);
  });
}

export async function stopRecordingAndCreateFile(word: string, isCatalan: boolean, wordId: number): Promise<File> {
  const [buffer, blob] = await recorder.stop().getMp3();
  const file = new File(buffer, `${word}-${isCatalan ? 'cat' : 'eng'}-${wordId}.mp3`, {
    type: blob.type,
    lastModified: Date.now()
  });
  return file;
}

export function previewRecording(file: File) {
  const player = new Audio(URL.createObjectURL(file));
  player.play();
}

export async function uploadRecording(file: File, duration: number, wordId: number) {
  const formData = new FormData();
  formData.append('audio', file);
  formData.append('duration', `${duration}`);
  formData.append('wordId', `${wordId}`);
  const resp = await fetch(`${APP_ROOT_URI}/audio`, { method: 'POST', body: formData });
  return resp.json();
}