import { Translation, Word } from 'catalan-dictionary-common';
import WordLink from './WordLink';
import { PlaySoundIcon } from '../audio/PlaySoundIcon';


 interface DefinitionHeaderProps {
   wordDef: Word,
 }

function DefinitionHeader(props: DefinitionHeaderProps) {
  // pick out the first relevant translation and gender(s)
  let firstTranslation: Translation | undefined = undefined;
  const definitions = props.wordDef.definitions;
  for (const key in definitions) {
    if (definitions[key] &&
      definitions[key].translations &&
      definitions[key].translations[0] &&
      definitions[key].translations[0]._text) {
        firstTranslation = firstTranslation || definitions[key].translations[0];
        break;
    }
  }

  let firstTranslationHtml = <></>;
  if (firstTranslation) {
    firstTranslationHtml = (
      <h3>
        <WordLink word={firstTranslation._text} wordCanonical={firstTranslation._wordCanonical} isCatalan={!props.wordDef.isCatalan} matchedText={firstTranslation._matchedText} />
      </h3>
    )
  }

  return (
    <div className="definition-header">
      <h3>
        {props.wordDef.word}
        <PlaySoundIcon soundUrl={props.wordDef.audio_data} />
      </h3>
      {firstTranslationHtml}
    </div>
  )
}

export default DefinitionHeader;