import './definition-page.scss';

import {
  RELEVANT_DEFINITION_GROUP_TO_DISPLAY_NAME,
  Word,
} from 'catalan-dictionary-common';
import TranslationsGroup from './TranslationsGroup';
import Expressions from './Expressions';

interface Props {
  wordDef: Word,
}

export default function TranslationsContent(props: Props) {
  const { wordDef } = props;

  if (!wordDef) {
    return <></>;
  }

  const calculateGenderString = (gendersByType: Map<string, string>) => {
    // handle case where it depends on the word (e.g. "pioner") or
    // it depends on the translation
    if (gendersByType.has('m / f') ||
        gendersByType.has('mf') ||
        (
          (gendersByType.has('m') || gendersByType.has('mpl')) &&
          (gendersByType.has('f') || gendersByType.has('fpl'))
        )
      ) {
      return 'Masculine or Feminine Noun';
    }

    if (gendersByType.has('m') || gendersByType.has('mpl')) {
      return 'Masculine Noun';
    }
    if (gendersByType.has('f') || gendersByType.has('fpl')) {
      return 'Feminine Noun';
    }

    return 'Noun';
  };

  const calculateHeaderString = (translationGroup: string, translations: any[]) => {
    if (!wordDef.isCatalan || translationGroup !== 'nouns') {
      return RELEVANT_DEFINITION_GROUP_TO_DISPLAY_NAME.get(translationGroup);
    }

    // need to determine genders
    const gendersByType = new Map<string, string>();
    for (const translation of translations) {
      if (translation.gender) {
        gendersByType.set(translation.gender, translation.gender);
      }
    }
    return calculateGenderString(gendersByType);
  };

  const definitionsHtml = Object.keys(wordDef.definitions).map((translationGroup) => {
    if (!RELEVANT_DEFINITION_GROUP_TO_DISPLAY_NAME.has(translationGroup)) {
      return undefined;
    }

    const heading = calculateHeaderString(translationGroup, wordDef.definitions[translationGroup].translations);
    return (
      <div className="row translation-group" key={`translationGroup-${translationGroup}`}>
        <div className="col">
          <h4 className="translation-heading">{heading}</h4>
          <TranslationsGroup translations={wordDef.definitions[translationGroup].translations} isCatalan={wordDef.isCatalan} />
        </div>
      </div>
    );
  }).filter((entry) => entry !== undefined);


  return (
    <div>
      <div className="citation">
        Definitions courtesy of&nbsp;
        <a href='http://www.catalandictionary.org/'>Catalan Dictionary Project (Diccionari Obert Català de Codi Obert)</a>
        , shared under&nbsp;
        <a href='https://github.com/cpina/dacco/blob/main/LICENSE'>GNU Lesser General Public License v2.1</a>.
      </div>

      {definitionsHtml}

      <div className="row">
        <div className="col">
          <Expressions expressions={wordDef.definitions.expressions} isCatalan={wordDef.isCatalan} />
        </div>
      </div>

      {/* <pre>
        {JSON.stringify(wordDef.definitions, null, 2)}
      </pre> */}
    </div>
  );

}