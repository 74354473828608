import './definition-page.scss';

import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { Word } from 'catalan-dictionary-common';
import DefinitionHeader from './DefinitionHeader';
import TranslationsContent from './TranslationsContent';
import WordConjugations from './WordConjugations';
import WordExamples from './WordExamples';
import WordAudioExamples from './WordAudioExamples';
import LoadingScreen from '../LoadingScreen';
import NoResults from '../NoResults';
import { APP_ROOT_URI } from '../../config';


const WORD_DEFINITION_URI = `${APP_ROOT_URI}/words`;

const enum Tabs {
  Definitions = 'Definitions',
  Conjugations = 'Conjugations',
  Examples = 'Examples',
  Audio = 'Audio'
};

function DefinitionPage() {
  const [isLoading, setIsLoading] = useState(true);
  const [wordDef, setWordDef] = useState<Word>();
  const [activeTab, setActiveTab] = useState(Tabs.Definitions);
  const { word, catOrEng } = useParams();

  // load word definition data from URL
  useEffect(() => {
    setIsLoading(true);
    (async () => {
      await fetch(`${WORD_DEFINITION_URI}/${word}/${catOrEng}`)
        .then((resp) => {
          return resp.json();
        })
        .then((word: Word) => {
          word.definitions = JSON.parse(word.definitions);
          setWordDef(word);
          setActiveTab(Tabs.Definitions);
          setIsLoading(false);
        });
    })();
  }, [word, catOrEng]);

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (!wordDef) {
    return <NoResults text='Sorry, no definition was available.' />;
  }

  const defineTab = (tab: Tabs) => {
    return (
      <li className="nav-item" key={tab.toString()}>
        <button
          onClick={() => setActiveTab(tab)}
          className={`nav-link btn-link ${(activeTab === tab) ? 'active' : ''}`}
        >
          {tab.toString()}
        </button>
      </li>
    );
  };
  const tabs = (() => {
    const tabs = [defineTab(Tabs.Definitions)];
    if (wordDef.conjugations) {
      tabs.push(defineTab(Tabs.Conjugations));
    }
    tabs.push(defineTab(Tabs.Examples));
    if (wordDef.isCatalan) {
      tabs.push(defineTab(Tabs.Audio));
    }
    return (
      <ul className="nav nav-tabs">
        {tabs}
      </ul>
    );
  })();

  const content = () => {
    switch (activeTab) {
      case Tabs.Definitions: return <TranslationsContent wordDef={wordDef} />;
      case Tabs.Conjugations: return <WordConjugations wordDef={wordDef} />;
      case Tabs.Examples: return <WordExamples wordDef={wordDef} />;
      case Tabs.Audio: return <WordAudioExamples wordDef={wordDef} />;
      // should never happen
      default: return <></>;
    }
  };

  return (
    <div>
      <div className="row">
        <div className="col">
          <DefinitionHeader wordDef={wordDef} />
        </div>
      </div>
      {tabs}
      {content()}
    </div>
  );
};

export default DefinitionPage;

