import { useState, useEffect } from 'react';
import Highlighter from 'react-highlight-words';

import { AudioExample, Word } from 'catalan-dictionary-common';
import LoadingScreen from '../LoadingScreen';
import NoResults from '../NoResults';
import { PlaySoundIcon } from '../audio/PlaySoundIcon';
import { APP_ROOT_URI } from '../../config';


interface Props {
  wordDef: Word,
}
export default function WordAudioExamples(props: Props) {
  const [isLoading, setIsLoading] = useState(true);
  const [examples, setExamples] = useState<AudioExample[]>([]);

  useEffect(() => {
    setIsLoading(true);
    (async () => {
      await fetch(`${APP_ROOT_URI}/examples?text=${encodeURIComponent(props.wordDef.word)}&audio=1`)
        .then((resp) => {
          return resp.json();
        })
        .then((examples: AudioExample[]) => {
          setExamples(examples);
          setIsLoading(false);
        });
    })();
  }, [props.wordDef]);

  if (isLoading) {
    return <LoadingScreen />;
  }
  if (!examples.length) {
    return <NoResults text='No examples found.' />;
  }

  const examplesContent = examples.map((example, index) => {
    return (
      <tr key={index}>
        <PlaySoundIcon soundUrl={example.audio_url} />
        <Highlighter
          highlightClassName="highlight"
          searchWords={[props.wordDef.word]}
          autoEscape={true}
          textToHighlight={example.cat_text}
        />
      </tr>
    );
  });

  return (
    <div className="examples">
      <div className="citation">
        Audio examples are courtesy of&nbsp;
        <a href='https://commonvoice.mozilla.org/ca'>Common Voice Project</a>
        , shared under&nbsp;
        <a href='https://creativecommons.org/licenses/by-sa/3.0/'>Creative Commons License (CC BY-SA 3.0)</a>.
      </div>

      <table className="table">
        <tbody>
          {examplesContent}
        </tbody>
      </table>
    </div>
  );

}
