"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RELEVANT_DEFINITION_GROUP_TO_DISPLAY_NAME = exports.TranslationType = void 0;
var TranslationType;
(function (TranslationType) {
    TranslationType["Nouns"] = "nouns";
    TranslationType["Verbs"] = "verbs";
    TranslationType["Adjectives"] = "adjectives";
    TranslationType["Exclamations"] = "exclamations";
    TranslationType["Adverbs"] = "adverbs";
    TranslationType["Prepositions"] = "prepositions";
    TranslationType["Abbreviations"] = "abbreviations";
    TranslationType["Conjunctions"] = "conjunctions";
    TranslationType["Pronouns"] = "pronouns";
    TranslationType["Determiners"] = "determiners";
    TranslationType["Phrases"] = "phrases";
})(TranslationType = exports.TranslationType || (exports.TranslationType = {}));
exports.RELEVANT_DEFINITION_GROUP_TO_DISPLAY_NAME = new Map();
exports.RELEVANT_DEFINITION_GROUP_TO_DISPLAY_NAME.set(TranslationType.Nouns, 'Noun');
exports.RELEVANT_DEFINITION_GROUP_TO_DISPLAY_NAME.set(TranslationType.Verbs, 'Verb');
exports.RELEVANT_DEFINITION_GROUP_TO_DISPLAY_NAME.set(TranslationType.Adjectives, 'Adjective');
exports.RELEVANT_DEFINITION_GROUP_TO_DISPLAY_NAME.set(TranslationType.Exclamations, 'Exclamation');
exports.RELEVANT_DEFINITION_GROUP_TO_DISPLAY_NAME.set(TranslationType.Adverbs, 'Adverb');
exports.RELEVANT_DEFINITION_GROUP_TO_DISPLAY_NAME.set(TranslationType.Prepositions, 'Preposition');
exports.RELEVANT_DEFINITION_GROUP_TO_DISPLAY_NAME.set(TranslationType.Abbreviations, 'Abbreviation');
exports.RELEVANT_DEFINITION_GROUP_TO_DISPLAY_NAME.set(TranslationType.Conjunctions, 'Conjunction');
exports.RELEVANT_DEFINITION_GROUP_TO_DISPLAY_NAME.set(TranslationType.Pronouns, 'Pronoun');
exports.RELEVANT_DEFINITION_GROUP_TO_DISPLAY_NAME.set(TranslationType.Determiners, 'Determiner');
exports.RELEVANT_DEFINITION_GROUP_TO_DISPLAY_NAME.set(TranslationType.Phrases, 'Phrase');
