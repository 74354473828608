import { Link } from 'react-router-dom';

interface Props {
  word: string,
  wordCanonical?: string,
  matchedText?: string,
  isCatalan: boolean,
}

function WordLink(props: Props) {
  // if we weren't able to find a link, just print the word
  if (!props.wordCanonical) {
    return <span>{props.word}</span>;
  }
  const link = `/translation/${props.wordCanonical}/${props.isCatalan ? 'cat' : 'eng'}`;
  const unmatchedText = props.word.replace((props.matchedText || ''), '');
  return (
    <span>
      {unmatchedText}<Link to={link}>{props.matchedText}</Link>
    </span>
  );
}

export default WordLink;